import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ControlInput } from '../../molecules/FormItem'
import { ControlPhoneInput } from '../../../youtalk-storybook/src/ui'
import { RU_COUNTRY_CODE } from '../../atoms/FormItems/formatters/phoneNumber'
import { composeValidators } from '../../atoms/FormItems/validators/composeValidators'
import { emailValidate } from '../../atoms/FormItems/validators/email'
import { notEmpty } from '../../atoms/FormItems/validators/notEmpty'
import { telephoneInternationalValidate } from '../../atoms/FormItems/validators/internationalTelephone'
import { telephoneValidate } from '../../atoms/FormItems/validators/telephone'
import { useField } from 'react-final-form'
import { useInputSizeProps } from './useInputSizeProps'

export const Name = ({ label, placeholder, name = 'name' }) => (
  <ControlInput
    required
    id={name}
    label={label || 'Имя'}
    name={name}
    placeholder={placeholder ?? 'Напишите своё имя'}
    validate={notEmpty('Пожалуйста, укажите имя')}
  />
)

const phoneValidateError = 'Укажите правильный номер телефона'

const isRussianPhone = (value) => (`+${value}` ?? '').indexOf('+8') === 0

export const Telephone = ({ description }) => {
  const { input } = useField('tel')
  const [telData, setTelData] = useState({ countryCode: 'ru' })

  const onChange = useCallback(
    (value, data) => {
      input.onChange(value)
      data && setTelData(data)
    },
    [input.onChange, telData]
  )

  useEffect(() => {
    isRussianPhone(input.value) && onChange(input.value.replace(/8/, '7'))
  }, [input.value])

  const validate = useMemo(
    () => telephoneValidate({ error: phoneValidateError, data: telData }),
    [telData]
  )

  return (
    <ControlPhoneInput
      required
      clearCondition={input.value?.replace(/\+/g, '')}
      description={description}
      label="Телефон"
      onChange={onChange}
      placeholder="7 (900) 123-45-67"
      validate={validate}
    />
  )
}

export const InternationalTelephone = ({ description, onChangeCountry }) => {
  const { input } = useField('tel')
  const { input: countyInput } = useField('countryCode')
  const sizeProps = useInputSizeProps()
  const [countryCode, setCountryCode] = useState(RU_COUNTRY_CODE)

  useEffect(() => {
    if (countryCode && typeof onChangeCountry === 'function') {
      onChangeCountry(countryCode)
    }
  }, [countryCode])

  useEffect(() => {
    isRussianPhone(input.value) && input.onChange(input.value.replace(/8/, '7'))
  }, [input.value])

  const onChangePhoneInput = useCallback(
    (_, info) => {
      if ('countryCode' in info) {
        const countryCode = info.countryCode.toUpperCase()
        setCountryCode(countryCode)
        countyInput.onChange(countryCode)
      }
    },
    [input]
  )

  const validate = useMemo(
    () =>
      telephoneInternationalValidate({
        error: phoneValidateError,
        country: countryCode
      }),
    [countryCode]
  )

  return (
    <ControlPhoneInput
      required
      clearCondition={input.value?.replace(/\+/g, '')}
      description={description}
      disableDropdown={false}
      label="Телефон"
      onChangePhoneInput={onChangePhoneInput}
      placeholder="7 (900) 123-45-67"
      validate={validate}
      {...sizeProps}
    />
  )
}

export const Email = styled(
  ({ className, description, placeholder, label }) => (
    <ControlInput
      required
      className={className}
      description={description}
      id="email"
      label={label ?? 'E-mail'}
      name="email"
      placeholder={placeholder ?? 'Электронная почта'}
      validate={composeValidators(
        notEmpty('Введите email'),
        emailValidate('Проверьте правильность написания почты')
      )}
    />
  )
)``
