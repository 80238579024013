import { parsePhoneNumber } from 'libphonenumber-js'

export const RU_COUNTRY_CODE = 'RU'

export const formatPhoneNumber = (tel, country) => {
  if (!tel || !country) {
    return tel
  }
  const phoneNumber = parsePhoneNumber(tel, country)

  if (country === RU_COUNTRY_CODE) {
    return phoneNumber.format('NATIONAL').replace(/8/, '+7')
  }

  return phoneNumber.formatInternational()
}
