import './index.scss'
import React from 'react'
import { Button } from '../../atoms/Button'

const ButtonPopup = ({ type, bnsText, hide, path }) =>
  type === 'link' ? (
    <Button.NewPrimary
      className="button button_primary"
      href={path}
      type="link"
    >
      {bnsText}
    </Button.NewPrimary>
  ) : (
    <Button.NewPrimary className="button button_primary" onClick={hide}>
      {bnsText}
    </Button.NewPrimary>
  )

const ThxPopupContent = ({
  title,
  desc,
  hide,
  bnsText,
  type = 'button',
  path = '/',
  imgSrc
}) => (
  <div className="thx-popup">
    <img
      alt=""
      className="thx-popup__img"
      src={imgSrc ?? '/img/icons/zayavkaOtpravlena.svg'}
    />
    <h3 className="thx-popup__title">{title}</h3>
    <p className="thx-popup__desc">{desc}</p>
    <ButtonPopup bnsText={bnsText} hide={hide} path={path} type={type} />
  </div>
)

export default ThxPopupContent
