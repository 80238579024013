import { RU_COUNTRY_CODE } from '../formatters/phoneNumber'
import { parsePhoneNumber } from 'libphonenumber-js'

export const telephoneInternationalValidate =
  ({ error: errorMessage, country }) =>
  (value) => {
    if (!value) {
      return errorMessage
    }
    if (country === RU_COUNTRY_CODE && value.length !== 11) {
      return errorMessage
    }
    try {
      const phoneNumber = parsePhoneNumber(value, country)

      if (!phoneNumber) {
        return errorMessage
      }

      return phoneNumber.isValid() ? undefined : errorMessage
    } catch {
      return errorMessage
    }
  }
