import Cookies from 'js-cookie'
import { getGoogleIDValue } from '../cookie/getGoogleIDValue'
import { getInitialQueryParamsValue } from './initialQueryParams'
import { isBrowser } from './root'
import { keys, omit } from 'ramda'
import { useEffect } from 'react'
import { useForm } from 'react-final-form'

export const useInitialFormValuesFromParams = (params) => {
  const { change, batch } = useForm()
  useEffect(() => {
    if (isBrowser) {
      window.requestIdleCallback(() =>
        batch(() => {
          params.forEach(({ fieldName, queryParam }) =>
            change(
              fieldName,
              getInitialQueryParamsValue(queryParam ?? fieldName)
            )
          )
        })
      )
    }
  }, [])
}

export const useInitialFormValuesAfterDOMLoad = (fields, conditions = true) => {
  const { change, batch } = useForm()
  useEffect(() => {
    if (isBrowser && conditions) {
      window.requestIdleCallback(() =>
        batch(() => {
          fields.forEach(({ fieldName, value }) => change(fieldName, value))
        })
      )
    }
  }, [])
}

export const getAnalyticsDataFromCookies = () => ({
  admitadUid: Cookies.get('admitad_uid'),
  googleClientID: getGoogleIDValue(Cookies.get('_ga')),
  yaCid: Cookies.get('_ym_uid'),
  fbc: Cookies.get('_fbc'),
  fbp: Cookies.get('_fbp'),
  entryUrl: localStorage.getItem('entryUrl')
})

export const toSendDataFormat = ({
  formData,
  utm,
  sourceId,
  pointOfContact
}) => ({
  name: formData.name,
  age: Number(formData.age),
  email: formData.email,
  registrationSource: formData.registrationSource,
  phone: formData.tel.replace(/[^0-9]+/g, ''),
  promocodeId: formData.promocodeId,
  attempt: formData.attempt,

  userRegistrationPreferences: formData.userRegistrationPreferences,

  analyticsData: {
    sourceId,
    pointOfContact,
    uid: utm.uid,
    utmSource: utm.utm_source,
    utmCampaign: utm.utm_campaign,
    utmMedium: utm.utm_medium,
    utmTerm: utm.utm_term,
    utmContent: utm.utm_content,
    ...getAnalyticsDataFromCookies()
  }
})

export const submit =
  ({ handleSubmit, errors, restart, initialValues }) =>
  async (event) => {
    event.preventDefault()
    const error = await handleSubmit(event)

    if (error) return error

    if (!keys(errors).length && !error) restart(initialValues)
  }

export const setError = ([fieldName, error], state) => {
  const { formState } = state

  formState.errors = {
    ...formState.errors,
    [fieldName]: error
  }
}

export const setTouched = ([fieldName], state) => {
  const { fields } = state
  fields[fieldName].touched = true
}

export const clearError = ([fieldName], state) => {
  const { formState } = state

  formState.errors = omit([fieldName], formState.errors)
}

export const clearSubmitError = ([fieldName], state) => {
  const { formState } = state

  formState.submitErrors = omit([fieldName], formState.submitErrors)
}
