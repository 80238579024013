import { isEmpty } from 'rambda'

const russianFirstNumbersPattern = /^(\+7|7)/

const isRussianNumber = (number) => russianFirstNumbersPattern.test(number)

export const telephoneValidate =
  ({ error: errorMessage, data: telData }) =>
  (value) => {
    if (value?.length < 11 && isRussianNumber(value)) {
      return errorMessage
    }

    if (!value || isEmpty(telData ?? {})) return errorMessage

    if (value?.length < 10) return errorMessage
  }
